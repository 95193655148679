.footer {
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 3%;
  max-height: 25px;
  margin: auto;
  border-radius: 25px;
  color: rgb(0, 0, 0);
  position: sticky;
  top: 100%;
  padding: 1px;
  display:flex;
  font-size: xx-small;
}