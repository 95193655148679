.menuTitle {
  color: red;
  text-decoration: underline;
  font-weight: bolder;
  text-align: center;
}

.divMenuLogo {
  width: 100%;
  display: flex;
}

.imgMenuLogo {
  width: 100%;
  float: left;
}
.menu {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  width: 100% !important;
}

.divLanguagesList {
  width: 100%;
}


/* Semantic overrides */
.ui.vertical.menu .item {
  padding-top: 4px;
  padding-bottom: 4px;
  color: blue;
}

.ui.vertical.menu .active.item {
  background-color: rgba(30,0,255,0.5);
  color: white;
}

.ui.vertical.menu .item:hover {
  background-color: rgba(30,0,255,0.20);
  color: blue;
  font-weight: bolder;
  text-decoration: underline;
}

.ui.vertical.menu .active.item:hover {
  background-color: rgba(30,0,255,0.5);
  color: white;
  text-decoration: none;
  font-weight: unset;
}

.ui.vertical.menu .world.icon {
  color: rgb(0,0,255); 
  float: left; 
  margin: 0 0 10px 5px;
  text-decoration: underline;
}

.ui.floating.dropdown.labeled.search.icon.button {
  background-color: rgba(255,255,255,0.3);
  border-radius: 1px;
  width: 100%;
  cursor: pointer;
  margin-left: 0 !important;
}

.ui.search.dropdown.text {
  cursor: pointer;
  margin: 0;
  font-size: 1.2vmin;
  left: 0;
}

.ui.styled.accordion {
  background-color: rgba(0,0,0,0);
  margin: auto;
}
.ui.styled.accordion .title {
  color: blue;
  font-weight: normal;
  font-size: 14px;
  padding: 0;
}
.ui.styled.accordion .active.title {
  color: blue;
  font-weight: normal;
  text-decoration: underline;
  font-size: 18px;
}

.ui.styled.accordion .active.item {
  color: white !important;
}
.ui.styled.accordion .content {
  padding: 0 !important;
}
.accordion .title:hover {
  background-color: rgba(30,0,255,0.20) !important;
  color: blue !important;
  font-weight: bolder !important;
  text-decoration: underline !important;
}

select.ui.dropdown {
  height: 100%;
  width: 100%;
  padding: 0;
}

/* Media options */
@media (max-width: 760px) {
  /* Semantic overrides */
  .ui.vertical.menu .item {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
  }
  .ui.styled.accordion .title {
    font-size: 18px;
  }
}