.headerFull {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.divBigBofContainer {
  width: 28%;
  margin-left: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 1;
}

.divBigBofText {
  max-width: 100%;
  font-size: 22px;
  float: left;
}

.divCounterFull {
  width: 68%;
  margin-right: 2%;
  float: right;
  order:2;
  display: flex;
  justify-content: flex-end;
}

.divBarsIcon {
  order: 3;
  margin: auto;
  margin-right: 8px;
  font-size: 22px;
}

i.bars.icon{
  display: none;
  color: red;
  cursor: pointer;
}

i.times.icon{
  display: none;
  color: red;
  cursor: pointer;
}

.divRemainingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.divCounterTitle {
  color: blue;
  order: 1;
  margin: auto;
  font-size: 14px;
}

.divCounters {
  order: 2;
  margin: auto;
}

.divCounterMain {
  width: 30px;
  float: left;
  padding: 0 0 2px 2px;
  margin-right: 5px;
}

.divCounterTop {
  font-size: 1.8vw;
}

.divCounterBottom {
  background-color: rgba(0,0,0,0.3);
  color: white;
  border-radius: 8px;
}

@keyframes rotation{ 100% {transform: rotate(360deg);}}

/* Media options */
@media (max-width: 760px) {
  .divBigBofText {
    font-size: 22px;
  }

  i.bars.icon{
    display: block;
  }

  i.times.icon{
    display: none;
  }
}